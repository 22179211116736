
import Vue from 'vue';
import axios, { AxiosResponse } from 'axios';

export default Vue.extend({
  methods: {
    resetSSCache(): void {
      axios
        .get('/api/resetCache')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          if (resp?.data?.status) {
            let message = resp.data.status;
            if (message === 'ok') {
              message = 'cache cleared';
            }
            this.$store.dispatch('showError', { message, errorType: 'success' });
            return;
          }
          this.$store.dispatch('showError', 'error :(');
          // eslint-disable-next-line no-console
          console.error(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) => {
          this.$store.dispatch('showError', err);
        });
    },
    resetExportCache(): void {
      axios
        .get('/api/resetExport')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          if (resp?.data?.status) {
            let message = resp.data.status;
            if (message === 'ok') {
              message = 'cache cleared';
            }
            this.$store.dispatch('showError', { message, errorType: 'success' });
            return;
          }
          this.$store.dispatch('showError', 'error :(');
          // eslint-disable-next-line no-console
          console.error(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) => {
          this.$store.dispatch('showError', err);
        });
    },
    testPDFExtract(): void {
      window.open('/api/testPDFExtract', '_blank');
    },
    testDevDeltaLake1(): void {
      window.open('/api/testDevDeltaLake1', '_blank');
    },
    testDevDeltaLake2(): void {
      window.open('/api/testDevDeltaLake2', '_blank');
    },
    testDevDeltaLake3(): void {
      window.open('/api/testDevDeltaLake3', '_blank');
    },
    testDevDeltaLake4(): void {
      window.open('/api/testDevDeltaLake4', '_blank');
    },
    testGraphQL(): void {
      this.$store
        ?.dispatch('layoutEditor/gqlGetProductsForAdvertiser')
        .then(data => {
          // eslint-disable-next-line no-console
          console.log('testGraphQL', data);
          this.$store.dispatch('showError', {
            message: `fetched ${data.getProductsForAdvertiser.length} records`,
            errorType: 'success',
          });
        })
        .catch(exp => {
          // eslint-disable-next-line no-console
          console.log(exp);
          this.$store.dispatch('showError', exp);
        });
    },
  },
});
